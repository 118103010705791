


















































































































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  DataDictionaryDtoPagedResultDto,
  FundDto,
  FundDtoPagedResultDto,
  FundUserDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
    MultipleUploadFile,
    SimpleUploadImage,
  },
})
export default class CreateNotify extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: FundUserDto = {
    id: 0,
  };
  dutyList: any = [];
  sexList: any = [];
  sex = "";
  fundList: FundDto[] = [];
  roleRule = {
    userName: [
      {
        required: true,
        message: "账号必填",
        trigger: "blur",
      },
    ],
    fundId: [
      {
        required: true,
        message: "所属基金必填",
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        message: "密码必填",
        trigger: "blur",
      },
    ],
    duty: [
      {
        required: true,
        message: "职务必填",
        trigger: "blur",
      },
    ],
    name: [
      {
        required: true,
        message: "昵称必填",
        trigger: "blur",
      },
    ],
    surname: [
      {
        required: true,
        message: "真实姓名必填",
        trigger: "blur",
      },
    ],
    emailAddress: [
      {
        required: true,
        message: "邮箱必填",
        trigger: "blur",
      },
      {
        message: "请填写正确的邮箱",
        type: "email",
        trigger: "blur",
      },
    ],
  };

  get hostTypeFile() {
    return AttachmentHostType.FundApply;
  }

  created() {
    if ((this as any).$route.params.id) {
      api.fundUser
        .get({ id: Number((this as any).$route.params.id) })
        .then((res) => {
          this.form = { ...res };
        });
    }
    this.fetchDataDictionary();
    this.fetchFundList();
  }

  async fetchFundList() {
    await api.fund
      .getAll({ pageSize: 65535 })
      .then((res: FundDtoPagedResultDto) => {
        this.fundList = res.items ?? [];
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "Duty", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.dutyList = res.items;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "Sex", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.sexList = res.items;
      });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.fundUser.update({
            body: this.form,
          });
        } else {
          await api.fundUser.create({
            body: this.form,
          });
        }
        (this as any).$router.back();
        (this as any).$message.success("更新成功");
      } else {
        (this as any).$message.error("请检查表单！");
      }
    });
  }

  cancel() {
    (this as any).$router.back();
    // (this.$refs.dataForm as any).resetFields();
    // this.$emit("input", false);
  }
}
